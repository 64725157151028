<template>
  <div>
    <div class="content mt-3 position-relative">
      <div class="position-absolute d-none" style="right: 0; top: 0">
        <el-switch
          class="mr-3"
          v-model="voiceSwitch"
          active-color="#ff4949"
          inactive-color="#979797"
        >
        </el-switch
        >{{ voiceSwitch ? "打开声音" : "关闭声音" }}
      </div>
      <div>
        <div class="family text-center" style="font-size:38px;margin: 10px auto;">对战回合</div>
        <!-- 对战盒子展示 -->
        <div class="d-flex content-bout history_bg align-items-center">
          <div
            class="content-bout-box d-flex"
            :class="detailsData.status == 2 ? '' : ''"
          >
            <div
              v-for="(item, index) in detailsData.game_arena_box"
              :key="index"
              class="text-center content-bout-box-item"
              @click="lockBoxGun(item, index)"
              :class="
                index == indexBox
                  ? detailsData.status == 2
                    ? ''
                    : 'active'
                  : ''
              "
            >
              <img width="100%" :src="item.box.intact_cover" alt="" />
              <div class="mt-2" style="font-size: 12px">
                {{ item.box.name || "" }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-pk d-flex flex-wrap overflow-hidden">
        <div
          :class="`col-${12 / (detailsData.user_num * 1)}`"
          class="content-pk-item p-1"
          v-for="(item, index) in detailsData.game_arena_player"
          :key="index"
        >
          <div
            v-if="item"
            :class="item.success ? '' : detailsData.status == 2 ? '' : ''"
            class="overflow-hidden"
          >
            <div
              class="content-pk-item-pk gohome d-flex"
              :class="item.game_gun ? 'active_light content-pk-item-pk-bg' : ''"
            >
              <div class="content-pk-item-pk-box" :class="openAn ? 'an' : ''">
                <div
                  class="text-center content-pk-item-pk-box-item an-item position-relative"
                  :name="t.a"
                  v-for="(t, index) in item.game_arena_box[indexBox].box
                    .contains"
                  :key="index"
                >
                  <div v-if="detailsData.status == 2">
                    <div v-if="item.success">
                      <button
                        style="
                          --content: '大获全胜';
                          font-size: 12px;
                          background-color: #3c6 !important;
                          color: #fff;
                        "
                        class="sefl_pk_button"
                      >
                        <div class="self_pk_right"></div>
                        大获全胜
                        <div class="self_pk_right"></div>
                      </button>
                      <div
                        style="
                          font-size: 14px;
                          font-weight: 700;
                          margin-top: 20px;
                          color: #3c6;
                        "
                      >
                        胜利$<countTo
                          :decimals="2"
                          :startVal="0"
                          :endVal="item.sum * 1"
                          :duration="2000"
                        ></countTo>
                      </div>
                      <div
                        class="m-auto d-flex justify-content-center align-items-center"
                      ></div>
                    </div>
                    <div v-else>
                      <button
                        style="
                          --content: '饮恨败北';
                          font-size: 12px;
                          background-color: #dd5d47 !important;
                          color: #fff;
                        "
                        class="sefl_pk_button"
                      >
                        <div class="self_pk_right"></div>
                        饮恨败北
                        <div class="self_pk_right"></div>
                      </button>
                      <div
                        style="
                          font-size: 14px;
                          font-weight: 700;
                          margin-top: 20px;
                          color: #ddd;
                        "
                      >
                        奖励$0.10
                      </div>
                    </div>
                  </div>

                  <div v-if="detailsData.status == 0">
                    <div class="m-auto">
                      <button class="sefl_pk_button">
                        <div class="self_pk_right"></div>
                        准备就绪
                        <div class="self_pk_right"></div>
                      </button>
                    </div>
                  </div>

                  <div v-if="detailsData.status == 1">
                    <div
                      v-if="moneyJieShu"
                      style="font-size: 20px"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <money :font-size="'20px'" />
                      <countTo
                        :decimals="2"
                        :startVal="0"
                        :endVal="item.money * 1"
                        :duration="1000"
                      >
                      </countTo>
                    </div>
                    <div v-else>
                      <div
                        class="mt-1 text-overflow-2 position-absolute top-0"
                        style="font-size: 12px; line-height: 16px; left: 10%"
                      >
                        {{ t.skins.name
                        }}<span style="font-size: 14px; color: #2ac88a"
                          ><money />{{ t.skins.bean }}</span
                        >
                      </div>
                      <img
                        class="content-pk-item-pk-box-item-gun"
                        :style="`${
                          12 / (detailsData.user_num * 1) == 6
                            ? 'padding: 20px;'
                            : ''
                        }`"
                        height="100%"
                        :name="t.a"
                        :src="t.skins.cover"
                        alt="dura_alias"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="
                item.success
                  ? ' active'
                  : detailsData.status == 2
                  ? 'pk_lose_bg'
                  : ''
              "
              style="margin-top:10px;"
              class="d-md-flex pk_win_bg content-pk-item-sum align-items-center justify-content-between"
            >
              <img
                width="30px"
                height="30px"
                class="rounded-pill"
                :src="item.user.avatar"
                alt=""
                style="margin-bottom: 10px"
              />
              <div class="d-flex align-items-center justify-content-center">
                <div class="text-overflow-1">{{ item.user.name }}</div>
              </div>

              <div v-if="detailsData.status == 0" class="d-md-block">
                {{ detailsData.status_alias }}
              </div>

              <div v-if="detailsData.status == 1" class="d-flex mt-3">
                <span class="d-md-flex d-none"
                  >{{ detailsData.status_alias }}
                </span>
                <money class="ml-2"></money>

                <countTo
                  :decimals="2"
                  :startVal="0"
                  :endVal="item.money * 1"
                  :duration="1000"
                ></countTo>
              </div>
              <div
                v-if="detailsData.status == 2"
                class="mt-3 text-center justify-content-center d-flex"
              >
                <span class="d-md-block d-none">获取的金币</span>
                <money class="ml-2" style="font-size: 14px"></money
                ><span style="font-size: 16px"> {{ item.sum || "0.01" }}</span>
              </div>
              <div style=""></div>
            </div>

            <div
              v-if="detailsData.status == 1"
              class="d-flex pk_gun_bg flex-wrap w-12"
            >
              <div
                class="content-award col-lg-4 col-md-4 col-sm-6 col-12 animate__animated"
                :class="t.skins.game_gun ? 'content-award-active' : ''"
                v-for="(t, i) in item.game_award_pk"
                :key="i"
              >
                <img width="55%" :src="t.skins.cover" alt="" />
                <div
                  class="text-overflow-1 text-center"
                  style="font-size: 12px"
                >
                  {{ t.skins.name }}
                </div>
                <div
                  class="mt-2 d-flex align-items-center justify-content-center"
                  style="
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--pk-green);
                  "
                >
                  <money font-size="12px"></money>
                  {{ t.skins.bean }}
                </div>
              </div>
            </div>

            <div
              v-if="detailsData.status == 2"
              class="d-flex pk_gun_bg flex-wrap w-12"
            >
              <div
                class="content-award col-lg-4 col-md-4 col-sm-6 col-12"
                v-for="(t, i) in item.game_award"
                :key="i"
              >
                <img width="62%" :src="t.skins.cover" alt="" />
                <div
                  class="text-overflow-1 text-center m-auto"
                  style="font-size: 12px; width: 80%"
                >
                  {{ t.skins.name }}
                </div>
                <div
                  class="mt-2 d-flex align-items-center justify-content-center"
                  style="
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--pk-green);
                  "
                >
                  <money font-size="12px"></money>
                  {{ t.skins.bean || (fildErr / yessSum).toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
          <!-- 点击加入房间 -->
          <template v-if="!item">
            <div class="overflow-hidden">
              <div class="content-pk-item-pk-box-item gohome">
                <div style="" @click="addPk(index)" class="text-center">
                  <button
                    style="font-size: 10px; margin-top: 21px"
                    class="sefl_pk_button"
                  >
                    立即参与
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 箱子里面展示的内容 -->
    <div v-if="lockBoxGunIsShow">
      <div
        class="position-fixed pop-up content-pop z-index-100"
        style="top: 12px"
      >
        <div>
          <div class="mt-3 ml-3">包含以下奖励</div>
          <div
            class="d-flex flex-wrap content-pop-lock align-content-start"
            style="padding-left: 15px"
          >
            <div
              v-for="(item, index) in boxGunListContains"
              :key="index"
              class="content-pop-lock-item col-lg-3 col-md-3 col-6"
            >
              <img
                width="100%"
                class="mx-auto d-block mt-3"
                :src="item.skins.cover"
                alt=""
              />
              <div class="text-center px-3 text-overflow-1">
                <div class="gunboxtext">{{ item.skins.name }}</div>
              </div>
              <div
                class="mx-auto d-flex justify-content-between align-items-center text-center p-2 mt-2 cursor-pointer"
                style=""
              >
                <div style="font-size: 12px; color: #ddd">
                  {{ item.skins.dura_alias }}
                </div>
                <div style="font-size: 12px; color: #75dc9e; font-weight: 900">
                  <money /> {{ item.skins.bean }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          @click="lockBoxGunIsShow = false"
          class="position-absolute"
          style="right: 20px; top: 30px"
        >
          <div>
            <i
              class="iconfont text-white icon-guanbi-02-02 cursor-pointer"
              style="font-size: 30px"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="position-fixed pk_gun_bg op-4 z-index-1"
        style="width: 100vw; height: 100vh; left: 0; top: 0"
      ></div>
    </div>
  </div>
</template>

<script>
// 能不动就不要动
// 能不动就不要动
// 能不动就不要动
import $api from "@/api/pk/index";
import countTo from "vue-count-to";

export default {
  name: "phoneWeb",
  components: { countTo },
  data() {
    return {
      voiceSwitch: true,
      game_arena_id: "",
      detailsData: {},
      box_num: 0,
      indexBox: 0,
      openAn: false,
      // openAn:true,
      status: 0,
      Against_the_end: false,
      sum: 0,
      fildErr: 0,
      yessSum: 1,
      full_house: 0,
      successAndErr: false,
      moneyJieShu: false,
      underway: 0,
      round: null,
      detailsDataUnderway: {},
      box_huihe: 0,
      time1: null,
      tiem2: null,
      time: null,
      huihe_gun: true,
      game_gun: false,
      settime: null,
      boxGunListContains: [],
      lockBoxGunIsShow: false,
    };
  },
  computed: {
    user_num: {
      get() {
        return (
          this.detailsData.user_num - this.detailsData.game_arena_player.length
        );
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    document.getElementById("content").scrollTop = 0;

    document.getElementById("open_pk").load();
    this.init();
  },
  created() {
    this.game_arena_id = this.$route.params.id;
    this.sum = this.$route.query.sum;
    this.underway = this.$route.query.underway;
    this.round = this.$route.query.round;
    this.initWebSocket();
  },
  beforeDestroy() {
    clearInterval(this.time1);
    clearInterval(this.tiem);
    clearInterval(this.tiem2);
    clearTimeout(this.settime);
    document.getElementById("open_pk").pause();
    this.$router.go(0);
  },
  watch: {
    detailsData: {
      handler(newVal) {
        this.detailsData = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async underwayPk() {
      let { data } = await $api.getPKarenaDetail(this.game_arena_id);
      let resData = JSON.parse(JSON.stringify(data));

      if (this.round > resData.box_num) {
        this.round = resData.box_num - 1;
      }
      resData.status = 1;
      resData.status_alias = "进行中";
      let box_huihe = this.round * 1;
      this.box_huihe = JSON.parse(JSON.stringify(box_huihe));
      this.indexBox = box_huihe;
      await this.disposalData(resData);
      await this.turn();
    },
    async init() {
      if (this.underway == 1) {
        await this.underwayPk();
      } else {
        await this.getPKarenaDetail();
      }
    },
    async getPKarenaDetail() {
      try {
        let { data, code } = await $api.getPKarenaDetail(this.game_arena_id);
        if (code == 500) return;
        let obj = this.disposalData(data);
        this.detailsData = obj;
        let game_arena_player = JSON.parse(JSON.stringify(data));

        if (this.detailsData.status == 2) {
          if (obj.win_user_id.length >= 2) {
            this.yessSum = obj.win_user_id.length;
            let sum = 0;
            this.detailsData.game_arena_player.forEach((item) => {
              item.game_award.forEach((a) => {
                sum = sum + a.skins.bean * 1;
              });
            });

            this.detailsData.game_arena_player.forEach((item, index) => {
              item.success = false;
              obj.win_user_id.forEach((t) => {
                if (item.user_id == t) {
                  item.success = true;
                  item.sum = (sum / obj.win_user_id.length).toFixed(2);
                }
              });
              if (!item.success) {
                item.game_award.map((a) => {
                  this.fildErr = this.fildErr + a.skins.bean * 1;
                });
                item.game_award = [
                  {
                    skins: {
                      cover: require("@/assets/img/liangliang.png"),
                      name: "凉凉",
                      bean: 0.01,
                    },
                  },
                ];
              } else {
                item.game_award.push({
                  skins: {
                    cover: require("@/assets/img/money.png"),
                    name: "钱袋子",
                    bean: 0,
                  },
                });
              }
              item = game_arena_player.game_arena_player[index];
            });
          } else {
            let arrAllList = [];
            this.detailsData.game_arena_player.forEach((item) => {
              let arrList = JSON.stringify(item.game_award);
              arrAllList.push(...JSON.parse(arrList));
            });
            this.detailsData.game_arena_player.forEach((item) => {
              item.game_award = arrAllList;
              obj.win_user_id.map((t) => {
                if (item.user_id == t) {
                  item.success = true;
                  let sum = 1;
                  item.game_award.forEach((m) => {
                    sum = sum + m.skins.bean * 1;
                  });
                  item.sum = sum.toFixed(2);

                  return item.game_award;
                } else {
                  item.game_award = [
                    {
                      skins: {
                        cover: require("@/assets/img/liangliang.png"),
                        name: "凉凉",
                        bean: 0.01,
                      },
                    },
                  ];
                }
              });
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
      setTimeout(() => {
        this.successAndErr = true;
      }, 2000);
    },
    disposalData(res) {
      let data = JSON.parse(JSON.stringify(res));
      if (this.detailsData.status == 0) {
        this.detailsData = data;
        return;
      }
      data.game_arena_player.forEach((item) => {
        item.game_arena_box = JSON.parse(JSON.stringify(data.game_arena_box));
        item.game_award_pk = [];
        item.money = 0;
        item.game_gun = false;
        let awar_id = JSON.parse(JSON.stringify(item.game_award));
        let awar_id_arr = [];
        awar_id.forEach((i) => {
          awar_id_arr.push(i);
        });

        item.game_arena_box.forEach((e) => {
          let obj = awar_id_arr.find((t) => {
            if (e.box_id == t.box_id) {
              t.box_id = "";
              return e;
            }
          });
          let contains = [];
          for (let i = 0; i < 70; i++) {
            let index = Math.floor(Math.random() * e.box.contains.length);
            contains.push(e.box.contains[index]);
          }
          e.box.contains = [...contains];
          if (obj != undefined) {
            obj.level = 1;
            e.box.contains.forEach((item) => {
              if (item.skin_id == obj.skins.id) {
                obj.level = item.level;
              }
            });
            obj.a = "1";
            e.box.contains.splice(60, 1, obj);
          }
        });
      });
      let arrData = JSON.parse(JSON.stringify(data));
      let arrIndex = [];
      arrIndex.length = arrData.user_num;
      for (let index = 0; index < arrData.game_arena_player.length; index++) {
        let arrDataIndex = arrData.game_arena_player[index];
        arrIndex[arrDataIndex.seat] = arrDataIndex;
      }
      arrData.game_arena_player = arrIndex;

      this.detailsData = arrData;
      return arrData;
    },
    anwe_sort(arr) {
      let sort = [];
      for (let i = 0; i < arr.length; i++) {
        let item = arr[Math.floor(Math.random() * arr.length)];
        sort.push(item);
      }
      return sort;
    },
    async addPk(item) {
      if (this.voiceSwitch) {
        document.getElementById("mysound").play();
      }
      try {
        let obj = {
          game_arena_id: this.game_arena_id,
          seat: item,
        };
        let { message, code } = await $api.getPKarenaJoin(obj);
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            message: message,
            position: "top-left",
          });
          this.$store.dispatch("userInfo");
          this.user_num = this.sum * 1 + 1;
          console.log(this.user_num);
          if (this.detailsData.user_num == item + 1) {
            this.$router.push(
              "/active/mysteryBox/" +
                this.game_arena_id +
                "?underway=1&round=" +
                0
            );
            this.$router.go(0);
            return;
          } else {
            // console.log(this.detailsData.user_num ); //2
            // console.log(111111111);
            // console.log(item);//1
            item++;
            this.$router.go(0);
            // location.reload();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    turn() {
      if (this.round == this.detailsData.box_num) {
        this.$router.push(
          "/active/mysteryBox/" + this.game_arena_id + "?underway=0&round=" + 0
        );
        this.$router.go(0);
        return;
      }
      if (this.round > 0) {
        this.indexBox = this.round * 1;
        this.$router.push(
          "/active/mysteryBox/" +
            this.game_arena_id +
            "?underway=1&round=" +
            this.round
        );
      }
      this.settime = setTimeout(() => {
        this.openAn = true;
        if (this.voiceSwitch) {
          document.getElementById("open_pk").play();
        }
      }, 100);
      if (this.detailsData.status == 1) {
        this.tiem = setInterval(() => {
          this.detailsData.game_arena_player.forEach((item) => {
            item.money = (
              item.money * 1 +
              item.game_award[this.indexBox].skins.bean * 1
            ).toFixed(2);
            item.game_award_pk.push(item.game_award[this.indexBox]);
            item.game_gun = item.game_award[this.indexBox].skins.game_gun;
            setTimeout(() => {
              item.game_gun = false;
            }, 2000);
          });
        }, 7900);
        this.time1 = setInterval(async () => {
          this.indexBox += 1;
          if (this.indexBox >= this.detailsData.box_num) {
            try {
              document.getElementById("open_pk").pause();

              this.indexBox = 0;
              clearInterval(this.time1);
              clearInterval(this.tiem);
              this.openAn = false;
              // this.openAn = true
              this.moneyJieShu = true;
              this.detailsData.game_arena_player.map((item) => {
                item.money = 0;
                item.success = false;
                item.gun = false;
                item.game_award_pk.forEach((it) => {
                  it.gun = false;
                });
                this.detailsData.win_user_id.forEach((t) => {
                  if (item.user_id == t) {
                    item.success = true;
                  }
                });
                return item;
              });
              let i = 0;
              this.tiem2 = setInterval(() => {
                if (i > this.detailsData.game_arena_player.length + 1) {
                  this.getPKarenaDetail();
                  this.detailsData.status = 2;
                  clearInterval(this.tiem2);
                }
                try {
                  this.detailsData.game_arena_player.forEach((item) => {
                    item.money = (
                      item.money * 1 +
                      item.game_award[i].skins.bean * 1
                    ).toFixed(2);
                  });
                } catch (e) {
                  i = this.detailsData.game_arena_player.length + 2;
                  this.getPKarenaDetail();
                  this.detailsData.status = 2;
                  clearInterval(this.tiem2);
                }
                i++;
              }, 2000);
              if (this.$route.name == "mysteryBox") {
                this.$router.push(
                  "/active/mysteryBox/" +
                    this.game_arena_id +
                    "?underway=0&round=" +
                    this.indexBox
                );
              }
            } catch (e) {
              console.log(e);
            }
          } else {
            this.openAn = false;
            // this.openAn = true
            setTimeout(() => {
              this.openAn = true;
            }, 100);
            if (this.$route.name == "mysteryBox") {
              this.$router.push(
                "/active/mysteryBox/" +
                  this.game_arena_id +
                  "?underway=1&round=" +
                  this.indexBox
              );
            }
            if (this.voiceSwitch) {
              document.getElementById("open_pk").play();
            }
          }
        }, 9000);
      }
    },
    timeCeil(Data) {
      setInterval(() => {
        var time1 = Date.parse(Data.updated_at) / 1000;
        var time2 = Date.parse(new Date()) / 1000;
        let ceil = Math.ceil((time1 + Data.box_num * 10 - time2) / 10);
        Data.ceil = Data.box_num - ceil;
      }, 3000);
    },
    lockBoxGun(item) {
      this.lockBoxGunIsShow = true;
      this.boxGunListContains = item.box.contains;
    },
    initWebSocket() {
      let _this = this;
      this.$ws.addEventListener("message", (event) => {
        let data = event.data;
        let { Data, MsgType } = JSON.parse(data);
        /* 加入房间 */
        if (MsgType == "joinGroup") {
          if (Data.game_arena_id == _this.game_arena_id) {
            let data = JSON.parse(
              JSON.stringify(_this.detailsData.game_arena_player[0])
            );
            let { game_arena_box, game_award, game_award_pk, money } = data;
            let obj = {
              seat: Data.seat,
              game_arena_id: Data.game_arena_id,
              user: {
                siyecao: "四叶草",
                id: Data.user_id,
                name: Data.name,
                avatar: Data.avatar,
              },
              game_arena_box,
              game_award,
              game_award_pk,
              money,
            };

            _this.detailsData.game_arena_player.splice(obj.seat, 1, obj);
          }
        }
        if (
          MsgType == "startGroup" &&
          Data.game_arena_id == _this.game_arena_id
        ) {
          _this.detailsData.status = Data.status;
          if (
            this.$route.name == "mysteryBox" &&
            this.game_arena_id == Data.id
          ) {
            this.$router.push(
              "/active/mysteryBox/" +
                this.game_arena_id +
                "?underway=" +
                0 +
                "&round=0"
            );
          }
        }
        if (MsgType == "endGroup") {
          if (Data.id == _this.game_arena_id) {
            _this.full_house = JSON.parse(JSON.stringify(Data.status));
            this.timeCeil(Data);
            Data.status = 1;
            Data.status_alias = "进行中";
            _this.detailsData.status = 1;
            _this.detailsData.status_alias = "进行中";
            if (
              this.$route.name == "mysteryBox" &&
              this.game_arena_id == Data.id
            ) {
              this.$router.push(
                "/active/mysteryBox/" +
                  this.game_arena_id +
                  "?underway=" +
                  1 +
                  "&round=0"
              );
              _this.disposalData(Data);
              _this.turn();
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.gunboxtext {
  font-size: 12px;
  position: absolute;
  top: 10px;
  color: #ddd;
}
.active_light {
  position: relative;
  &::before {
    content: "";
    /*background-image: url("../../assets/img/pk/bg.png");*/
    width: 100%;
    left: 0 !important;
    position: absolute;
    top: 100%;
    left: 0 !important;
    z-index: -1;
    animation: light 1s;
    box-shadow: 0 0 50px 20px rgba(255, 0, 0, 0.8);
    animation-iteration-count: 2;
    opacity: 0;
  }
}
.active_light_err {
  position: relative;
  &::before {
    content: "";
    background-size: 80%;

    width: 100%;
    left: 0 !important;
    position: absolute;
    top: 100%;
    left: 0 !important;
    z-index: -1;
    animation: light 1s;
    box-shadow: 0 0 50px 20px rgba(63, 63, 63, 0.8);
    animation-iteration-count: 2;
    opacity: 0;
  }
}
.successAndErr {
  display: none;
}
.loading-zhuan {
  animation: clockwise_rotation 2.5s linear infinite;
}

/* 定义动画 */

.an .an-item {
  transition: all 7s cubic-bezier(0, 0, 0, 1) 0.1s !important;
  transform: translateY(-7800px) !important;
  position: absolute;
  z-index: 9999;
}
.content {
  width: 90%;
  margin: 0 auto;
  font-size: 12px;

  @media (max-width: 1200px) {
    width: 96%;
    left: 2%;
  }

  &-bout {
    width: 96%;
    padding: 10px 15px;

    &-sum {
      padding: 0 20px 0 0px;

      @media (max-width: 1200px) {
        display: none;
      }
    }

    &-box {
      &-item {
        margin: 0 10px;

        & img {
          @media (max-width: 1200px) {
            width: 60px !important;
          }
        }

        & div {
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 12px;
        }
      }

      & .active img {
        transform: scale(1.4);
        transition: all 0.5s;
      }
    }

    &-add-pk {
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }

  &-pk {
    &-item {
      margin-top: 0px;

      &-sum {
        padding: 10px 30px;

        @media (max-width: 1200px) {
          padding: 5px 2px;
          font-size: 10px;
          text-align: center;
        }
      }

      &-pk {
        overflow: hidden;
        padding: 10px 0px;
        height: 130px;
        position: relative;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;

        &-box {
          height: 130px;

          &-item {
            /*border-top: 1px solid rgba(251,202,1,1);*/
            /*background-image: linear-gradient(0deg,rgba(251,202,1,1), rgba(0,0,0,0));*/
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
            /*padding: 10px 30px;*/
            height: 130px;
            margin: 0px 0px;

            &-gun {
              /*padding: 20px;*/

              @media (max-width: 1200px) {
                width: 80%;
                text-align: center;
              }
            }
          }
        }
      }

      &-pk-bg {
        background-size: 80%;
      }

      & .active {
        /*background-image: url("../../assets/img/success.png");*/
        background-size: 100%;
      }

      & .error {
        /*background-image: url("../../assets/img/error.png");*/
        background-size: 100%;
      }
    }
  }

  &-award {
    /*background-image: linear-gradient(0deg,rgba(251,202,1,1), rgba(0,0,0,0));*/
    /*margin: 0 2px;*/
    /*border-top: 2px solid rgba(251,202,1,1);*/
    /*width: 230px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    /*    @media (max-width:1600px) {*/
    /*        width: 190px;*/
    /*    }*/
    /*    @media (max-width:1366px) {*/
    /*        width: 240px;*/
    /*    }*/
    /*    @media (max-width:1200px) {*/
    /*        width: 200px;*/
    /*    }*/
    /*    @media (max-width:960px) {*/
    /*        width: 200px;*/
    /*    }*/
    /*    @media (max-width:875px) {*/
    /*    width: 250px;*/
    /*}*/
    &-active {
      &::before {
        content: "";
        /*background-image: url("../../assets/img/pk/bg.png");*/
        width: 50%;
        left: 25%;
        height: 1px;
        background-size: 50% 50%;
        position: absolute;
        top: -0px;
        background-color: red;
        z-index: -1;
        animation: light 1s;
        box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.5);
        animation-iteration-count: 2;
        opacity: 0;
      }
    }
  }
}

@keyframes light {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.content-pop {
  width: 800px;
  left: calc(50% - 350px);
  top: 100px;
  height: 650px;

  &-box {
    height: 450px;
    width: 720px;
    margin: 0 auto;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      border: 1px solid #000000;
      width: 160px !important;
      background-color: #090827;

      &:hover &-add {
        display: block;
      }

      &-add {
        display: none;
      }
    }

    & .active {
      background-color: var(--main-yellow);
    }
  }

  &-lock {
    margin-top: 20px;
    height: 550px;
    overflow: scroll;
    overflow-x: hidden;

    &-item {
      position: relative;
      background-color: var(--f-main);
      border: 1px solid #000;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    left: 0;
    height: 650px;
  }
}
.gohome {
  border-radius: 10px;
  box-shadow: 0 0 65px 2px #ffffff inset;
  justify-content: center;
}
.content-pop-lock-item {
  background-color: #00000060;
  border: transparent !important;
  border-radius: 15px;
}
</style>
