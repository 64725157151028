<template>
  <div>
    <pk-web  class="web"></pk-web>
    <pk-phone  class="phone"></pk-phone>
  </div>

</template>

<script>
    import pkPhone from "./common/pkPhone";
    import pkWeb from "./common/pkWeb";
    import sUserAgent from '@/assets/js/common.js'
    
    export default {
        name: "active",
        components:{pkPhone,pkWeb},
      computed:{
        sUserAgent(){
          return sUserAgent.sUserAgent()
        }
      },
      created() {
          console.log(sUserAgent)
      },
      mounted() {
          console.log(sUserAgent)
      }
    }
</script>

<style scoped lang="scss">
.web{
  @media (max-width: 960px) {
    display: none;
  }
}
  .phone{
    display: none;
    @media (max-width: 960px) {
      display: block;
    }
  }
</style>
